import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TelegramService {
  private userApiUrl = 'https://telegram102.p.rapidapi.com/telegram/user-info';
  private groupApiUrl = 'https://telegram102.p.rapidapi.com/telegram/group-info';
  private channelApiUrl = 'https://telegram102.p.rapidapi.com/telegram/channel-info';  // New endpoint for channel info
  private botApiUrl = 'https://telegram102.p.rapidapi.com/telegram/bot-info';  // New endpoint for bot info

  constructor(private http: HttpClient) {}

  // Method to get user info
  getTelegramUserInfo(username: string): Observable<any> {
    const body = { username: username };
    return this.http.post(this.userApiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-host': 'telegram102.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'  // Replace with your actual key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Method to get group info
  getTelegramGroupInfo(groupUsername: string): Observable<any> {
    const body = { username: groupUsername };
    return this.http.post(this.groupApiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-host': 'telegram102.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'  // Replace with your actual key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Method to get channel info
  getTelegramChannelInfo(channelUsername: string): Observable<any> {
    const body = { username: channelUsername };
    return this.http.post(this.channelApiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-host': 'telegram102.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'  // Replace with your actual key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Method to get bot info
  getTelegramBotInfo(botUsername: string): Observable<any> {
    const body = { username: botUsername };
    return this.http.post(this.botApiUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-host': 'telegram102.p.rapidapi.com',
        'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'  // Replace with your actual key
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  // Error handling
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.message}`;
      console.error(`Error Details: ${JSON.stringify(error)}`);
    }
    return throwError(errorMessage);
  }
}
