import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {
  apiKey: string = 'AIzaSyAEFP_anG_iHtqvZSC5MoKNtuEB1xwcKQ0';

  private apiUrl = 'https://127.0.0.1:4999/api/youtube'; // Note: Changed port to 5000 to match the Flask example

  constructor(public http: HttpClient) { }
  
  getVideosForChanel(channel: string, maxResults: number): Observable<any[]> {
    let url = `https://www.googleapis.com/youtube/v3/search?key=${this.apiKey}&channelId=${channel}&order=date&part=snippet&type=video&maxResults=${maxResults}`;
    return this.http.get<any>(url).pipe(
      map((res: any) => res.items)  // Map the response to return the items array
    );
  }

  getChannelInfo(channelId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/${channelId}`);
  }
}
