import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  fileList() {
    return this.http.get(
      `http://localhost:4999/api/files`
    );
  }
}
