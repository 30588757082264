import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { UserRegisterRequest, UserMobileVerifyRequest } from '../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  // User registration data
  account: UserRegisterRequest = {
    email: '',
    mobileNo: '',
    password: '',
    name: '',
    countryCode: '',
    dob: '',
    referalCode: '',
    affiliateCode: ''
  };

  // Mobile verification data
  mobileVerification: UserMobileVerifyRequest = {
    mobileNo: '',
    code: ''
  };

  isMobileVerified: boolean = false;
  errorMessage: string = '';

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    // You can load existing account data if necessary
  }

  // Register new user
  onRegister(): void {
    this.userService.register(this.account).subscribe(
      (response: any) => {
        console.log('User registered successfully:', response);
        alert('Registration successful!');
        this.router.navigate(['/somewhere']);  // Navigate to another page if needed
      },
      (error) => {
        console.error('Error during registration:', error);
        this.errorMessage = 'Registration failed. Please try again.';
      }
    );
  }

  // Verify mobile number
  onVerifyMobile(): void {
    this.userService.verifyMobile(this.mobileVerification).subscribe(
      (response: any) => {
        console.log('Mobile verification successful:', response);
        this.isMobileVerified = true;
        alert('Mobile verification successful!');
      },
      (error) => {
        console.error('Error verifying mobile:', error);
        this.errorMessage = 'Mobile verification failed. Please check the code and try again.';
      }
    );
  }
}
