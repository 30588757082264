import { SnapComponent } from './snap/snap.component';
import { TelegramComponent } from './telegram/telegram.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { NgbConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';	
import { NgbdCarouselBasic } from './carousel-basic';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPayPalModule } from 'ngx-paypal';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
// Import containers
import { DefaultLayoutComponent } from './containers';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './helpers/auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

// import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AccountService, FileService } from './services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MainComponent } from './main/main.component';
import { FilesComponent } from './files/files.component';
import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';
import { DetailComponent } from './detail/detail.component';
import { UploadComponent } from './upload/upload.component';
import { ContentComponent } from './content/content.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { LogoutComponent } from './logout/logout.component';
import { FakenewsidentifierComponent } from './fakenewsidentifier/fakenewsidentifier.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InstagramComponent } from './instagram/instagram.component';
import { XComponent } from './x/x.component';
import { FacebookComponent } from './facebook/facebook.component';
import { NgxTwitterWidgetsModule } from "ngx-twitter-widgets";
import { EditAccountComponent, HomeComponent } from './home';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './helpers';
import { UrlDialogComponent } from './url-dialog/url-dialog.component';
import { TikTokComponent } from './tik-tok/tik-tok.component';
import { PaymentsComponent } from './payments/payments.component';
import { AccountComponent } from './account/account.component';



const APP_CONTAINERS = [
  DefaultLayoutComponent
];

@NgModule({
   imports: [
      BrowserModule,
      ReactiveFormsModule,
      HttpClientModule,
      AppRoutingModule,
      MatGridListModule,
      MatPaginatorModule,
      MatTableModule,
      MatSortModule,
      NgbModule,
      NgbdCarouselBasic,
      NgbCarousel,
      FormsModule,
      NgxPayPalModule,
      BrowserAnimationsModule,
      ModalModule.forRoot(),
      NgxSpinnerModule,
      NgxTwitterWidgetsModule,
      MatDialogModule,
      MatInputModule,
      MatButtonModule,
      MatFormFieldModule
   ],
   declarations: [
      AppComponent,
      SidebarComponent,
      HomeComponent,
      EditAccountComponent,
      LoginComponent,
      RegisterComponent,
      DashboardComponent,
      MainComponent,
      FilesComponent,
      DetailComponent,
      ContentComponent,
      UploadComponent,
      AnalysisComponent,
      LogoutComponent,
      YoutubeComponent,
      InstagramComponent,
      XComponent,
      FacebookComponent,
      FakenewsidentifierComponent,
      UrlDialogComponent,
      TikTokComponent,
      TelegramComponent,
      SidebarComponent,
      NavbarComponent,
      PaymentsComponent,
      AccountComponent,
      SnapComponent
   ],
   exports: [
      FormsModule,
      ReactiveFormsModule,
      AppRoutingModule,
      HttpClientModule
    ],
    providers: [
      {
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      },
      { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
      { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      AuthGuard,
      FileService,
    ],
   bootstrap: [
      AppComponent
   ],
   schemas: [
      CUSTOM_ELEMENTS_SCHEMA
   ]
})
export class AppModule { }
