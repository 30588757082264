import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components and AuthGuard
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { PaymentSuccessComponent } from './views/error/payment-success.module';
import { PaymentFailComponent } from './views/error/payment-fail.module';
import { LoginComponent } from './login/login.component';
import { TikTokComponent } from './tik-tok/tik-tok.component';
import { RegisterComponent } from './register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DetailComponent } from './detail/detail.component';
import { UploadComponent } from './upload/upload.component';
import { AnalysisComponent } from './analysis/analysis.component';
import { LogoutComponent } from './logout/logout.component';
import { YoutubeComponent } from './youtube/youtube.component';
import { XComponent } from './x/x.component';
import { TelegramComponent } from './telegram/telegram.component';
import { InstagramComponent } from './instagram/instagram.component';
import { PaymentsComponent } from './payments/payments.component';
import { AccountComponent } from './account/account.component';
import { SnapComponent } from './snap/snap.component';
import { FakenewsidentifierComponent } from './fakenewsidentifier/fakenewsidentifier.component';
import { AuthGuard } from './auth.guard'; // Import your AuthGuard

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Dashboard'
    }
  },
  {
    path: 'snapchat',
    component: SnapComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Snapchat'
    }
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Account'
    }
  },
  {
    path: 'payments',
    component: PaymentsComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Payments'
    }
  },
  {
    path: 'telegram',
    component: TelegramComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Telegram'
    }
  },
  {
    path: 'payment-failed',
    component: PaymentFailComponent,
    data: {
      title: ''
    }
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    data: {
      title: ''
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'detail',
    component: DetailComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Detail'
    }
  },
  {
    path: 'fakenews',
    component: FakenewsidentifierComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Fake News Identifier'
    }
  },
  { 
    path: '', 
    redirectTo: 'login', 
    pathMatch: 'full'  // Redirect to login as default 
  },
  { 
    path: 'login', 
    component: LoginComponent 
  },
  { 
    path: 'logout', 
    component: LogoutComponent 
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'upload',
    component: UploadComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Upload'
    }
  },
  {
    path: 'analysis',
    component: AnalysisComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Analysis'
    }
  },
  {
    path: 'tiktok',
    component: TikTokComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'TikTok Component'
    }
  },
  {
    path: 'youtube',
    component: YoutubeComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'YouTube Component'
    }
  },
  {
    path: 'instagram',
    component: InstagramComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'Instagram Component'
    }
  },
  {
    path: 'x-comp',
    component: XComponent,
    canActivate: [AuthGuard],  // Protected with AuthGuard
    data: {
      title: 'X Component'
    }
  },
  { 
    path: '**', 
    component: P404Component 
  }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
