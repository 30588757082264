import { Component, TemplateRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileService, PaymentService } from '../services';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  fruit: string;
}

/** Constants used to fill up our data base. */
const FRUITS: string[] = [
  'Automotive',
  'Fashion',
  'E-Commerce',
  'Banking',
  'Finance',
  'Tech',
  'Environment',
  'Music',
];
const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements AfterViewInit {
  file: File;
  price: number;
  tags: string;
  tagsArray: string[] = [];
  uploadedFile: any;
  thumbnailUrl: string;
  public data: any = [];
  title = 'Creator matching';
  input: any; 
  
  displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private router: Router
  ) {
    // Create 100 users
    const users = Array.from({ length: 100 }, (_, k) => this.createNewUser(k + 1));
    this.dataSource = new MatTableDataSource(users);
    this.data = [];
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  

  ngOnInit() {
    this.fileService.fileList()
    .pipe(first())
    .subscribe(
      (list) => {
        this.data = list;
        //console.log('logging list',list);
        this.data.forEach((e1,i) => {
          e1.index = i;
          //console.log(e1.bytecode)
        });
      },
      (error) => {
        if (error.error.error?.verifyUser) {
        } else {
          // this.snackBService.openSnackBar(
          //   `Incorrect Username or Password !!!`,
          //   false
          // );
        }
      }
    );
  }

  
  onFileSelected(event): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.uploadedFile = {
        url: reader.result as string,
        type: file.type.split('/')[0]
      };

      this.generateThumbnail(reader.result as string);
    };
  }

  generateThumbnail(fileUrl: string): void {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = 500;
      canvas.height = 500;

      ctx.drawImage(img, 0, 0, 500, 500);

      this.thumbnailUrl = canvas.toDataURL();
    };

    img.src = fileUrl;
  }

  onSubmit() {
    this.tagsArray = this.tags.split(',').map((tag) => tag.trim());
    const formData = new FormData();
    formData.append('file', this.file);
    this.http.post('http://your-api-url/upload', formData).subscribe((response: any) => {
      this.uploadedFile = response;
      this.uploadedFile.type = this.file.type.split('/')[0];
      this.uploadedFile.url = 'http://your-file-server-url/' + response.filename;
      this.saveFileData();
    });
  }

  saveFileData() {
    const tagsArray = this.tags.split(',');
    tagsArray.forEach((tag: string) => {
      this.tagsArray.push(tag.trim());
    });
    const data = {
      url: this.uploadedFile.url,
      type: this.uploadedFile.type,
      price: this.price,
      tags: this.tagsArray
    };
    this.http.post('http://your-api-url/save-data', data).subscribe((response: any) => {
      console.log('File data saved successfully');
    });
  }
  

  payment() {
    this.paymentService
      .getPaymentLink()
      .pipe(first())
      .subscribe(
        (links: any) => {
          if (links) {
            let paymentLink = links.links.find(x => x.rel === 'approve');
            // this.router.navigate([paymentLink]);
            window.location.href = paymentLink.href
          }
        },
        (error) => {
          if (error.error.error?.verifyUser) {
            // this.openDialog(error.error.error?.user);
          } else {
            // this.snackBService.openSnackBar(
            //   `Incorrect Username or Password !!!`,
            //   false
            // );
          }
        }
      );
  }
  /** Builds and returns a new User. */
  createNewUser(id: number): UserData {
    const name =
      NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
      ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
      '.';
  
    return {
      id: id.toString(),
      name: name,
      progress: Math.round(Math.random() * 100).toString(),
      fruit: FRUITS[Math.round(Math.random() * (FRUITS.length - 1))]
    };
  }
}
