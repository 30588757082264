import { Component, OnInit, TemplateRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { FileService, PaymentService } from '../services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'files-cmp',
  templateUrl: 'files.component.html'
})
export class FilesComponent implements OnInit{
  public data: any = [];
  modalRef?: BsModalRef;
  paymentForm: UntypedFormGroup;

  public list
  constructor(
    private fileService: FileService,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private router: Router,
  ) {
    this.data = []
  }

  ngOnInit() {
    this.fileService.fileList()
    .pipe(first())
    .subscribe(
      (list) => {
        this.data = list;
        //console.log('logging list',list);
        this.data.forEach((e1,i) => {
          e1.index = i;
          //console.log(e1.bytecode)
        });
      },
      (error) => {
        if (error.error.error?.verifyUser) {
        } else {
          // this.snackBService.openSnackBar(
          //   `Incorrect Username or Password !!!`,
          //   false
          // );
        }
      }
    );
  }

  openModal(template: TemplateRef<any>, list:any) {
    console.log(list);
    this.list = list; // Dynamic Data
    this.modalRef = this.modalService.show(template);
  }

  callNextPage(template: TemplateRef<any>, list:any) {
    console.log(list);
    console.log('Next page called');
    this.list = list; // Dynamic Data
    this.modalRef = this.modalService.show(template);
  }

  deny(template: TemplateRef<any>,index){
    console.log('called');
    this.modalRef.hide();
    if(this.data[index+1]){
      this.list = this.data[index+1]; // Dynamic Data
      this.modalRef = this.modalService.show(template);
    }
  }
  

  payment() {
    this.paymentService
      .getPaymentLink()
      .pipe(first())
      .subscribe(
        (links: any) => {
          if (links) {
            let paymentLink = links.links.find(x => x.rel === 'approve');
            // this.router.navigate([paymentLink]);
            window.location.href = paymentLink.href
          }
        },
        (error) => {
          if (error.error.error?.verifyUser) {
            // this.openDialog(error.error.error?.user);
          } else {
            // this.snackBService.openSnackBar(
            //   `Incorrect Username or Password !!!`,
            //   false
            // );
          }
        }
      );
  }
}
