import { HttpClient } from '@angular/common/http';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FileService, PaymentService } from '../services';
import { UserData } from '../upload/upload.component';

const FRUITS: string[] = [
  'blueberry',
  'lychee',
  'kiwi',
  'mango',
  'peach',
  'lime',
  'pomegranate',
  'pineapple',
];

const NAMES: string[] = [
  'Maia',
  'Asher',
  'Olivia',
  'Atticus',
  'Amelia',
  'Jack',
  'Charlotte',
  'Theodore',
  'Isla',
  'Oliver',
  'Isabella',
  'Jasper',
  'Cora',
  'Levi',
  'Violet',
  'Arthur',
  'Mia',
  'Thomas',
  'Elizabeth',
];


@Component({
  selector: 'content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})


export class ContentComponent implements AfterViewInit {

  title = 'Upload your Company related / Brand related content';

  form: FormGroup;
  languages = ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese'];
  countries = ['USA', 'Canada', 'Mexico', 'Brazil', 'France', 'Germany', 'India', 'China', 'Japan'];
  platforms = ['Facebook', 'Twitter', 'Instagram', 'LinkedIn', 'YouTube'];

  file: File;
  price: number;
  tags: string;
  tagsArray: string[] = [];
  uploadedFile: any;
  thumbnailUrl: string;
  public data: any = [];
  private _input: any; 
  public get input(): any {
    return this._input;
  }
  public set input(value: any) {
    this._input = value;
  }

  displayedColumns: string[] = ['id', 'name', 'progress', 'fruit'];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private fileService: FileService,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private router: Router) {
    
    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      text: ['', [Validators.required, Validators.maxLength(500)]],
      image: ['', Validators.required],
      video: ['', Validators.required],
      tags: ['', [Validators.required, Validators.minLength(3)]],
      language: ['', Validators.required],
      country: ['', Validators.required],
      platform: ['', Validators.required],
      additionalPlatforms: ''
    });
    {
      // Create 100 users
      const users = Array.from({ length: 100 }, (_, k) => this.createNewUser(k + 1));
      this.dataSource = new MatTableDataSource(users);
      this.data = [];
    }
  }

  
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  onSubmit() {
    // Submit form logic here
  }

  createNewUser(id: number): UserData {
    const name =
      NAMES[Math.round(Math.random() * (NAMES.length - 1))] +
      ' ' +
      NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) +
      '.';
  
    return {
      id: id.toString(),
      name: name,
      progress: Math.round(Math.random() * 100).toString(),
      fruit: FRUITS[Math.round(Math.random() * (FRUITS.length - 1))]
    };
  }

  onFileSelected(event): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.uploadedFile = {
        url: reader.result as string,
        type: file.type.split('/')[0]
      };

      this.generateThumbnail(reader.result as string);
    };
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  generateThumbnail(fileUrl: string): void {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = 500;
      canvas.height = 500;

      ctx.drawImage(img, 0, 0, 500, 500);

      this.thumbnailUrl = canvas.toDataURL();
    };

    img.src = fileUrl;
  }
}



