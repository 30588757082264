<ngb-carousel *ngIf="images.length">
    <ng-template ngbSlide *ngFor="let image of images.slice(currentIndex, currentIndex + maxImages)">
      <div (click)="openDetailView(image)">
      <div class="picsum-img-wrapper">
        <img [src]="'data:image/png;base64,' + image.bytecode" alt="Random slide"/>
      </div>
      <div class="carousel-caption">
        <h3>Slide label</h3>
        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
      </div>
    </div>
    </ng-template>
  </ngb-carousel>
