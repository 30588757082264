import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TwitterService {
  private apiUrl = 'https://twitter154.p.rapidapi.com/user/details?username=';

  constructor(private http: HttpClient) {}

  getUser(username: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-rapidapi-host': 'twitter154.p.rapidapi.com',
      'x-rapidapi-key': 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'
    });
    return this.http.get<any>(`${this.apiUrl}${username}`, { headers });
  }
}
