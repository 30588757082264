import { Component } from '@angular/core';

@Component({
  templateUrl: 'paymentSuccess.component.html'
})
export class PaymentSuccessComponent {

  constructor() { }

}
