import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}

  getPaymentLink() {
    return this.http.get(
      `https://api.canpostthis.com/v1/payment`
    );
  }
}
