import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TikTokService {
  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your key
  private apiHost = 'tiktok-api23.p.rapidapi.com';

  constructor(private http: HttpClient) {}

  getTikTokProfile(username: string): Observable<any> {
    const url = `https://${this.apiHost}/api/user/info?uniqueId=${username}`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });
    return this.http.get(url, { headers });
  }

  // Method to get user posts
  getUserPosts(secUid: string): Observable<any> {
    const url = `https://${this.apiHost}/api/user/posts?secUid=${secUid}&count=35&cursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json',
    });
    return this.http.get(url, { headers });
  }

  // Method to get user information by uniqueId
  getUserInfo(uniqueId: string): Observable<any> {
    const url = `https://${this.apiHost}/api/user/info?uniqueId=${uniqueId}`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json'
    });

    return this.http.get(url, { headers });
  }

  // Method to get user followers
  getUserFollowers(secUid: string, count = 50): Observable<any> {
    const url = `https://${this.apiHost}/api/user/followers?secUid=${secUid}&count=${count}&minCursor=0&maxCursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json'
    });

    return this.http.get(url, { headers });
  }

  // Method to get user followings
  getUserFollowings(secUid: string, count = 50): Observable<any> {
    const url = `https://${this.apiHost}/api/user/followings?secUid=${secUid}&count=${count}&minCursor=0&maxCursor=0`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json'
    });

    return this.http.get(url, { headers });
  }

}
