import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TikTokService } from '../services/tiktok.service';

@Component({
  selector: 'app-tik-tok',
  templateUrl: './tik-tok.component.html',
  styleUrls: ['./tik-tok.component.scss']
})
export class TikTokComponent implements OnInit {
  errorMessage: string | null = null;
  user: any;
  fakeProbability: number = 0;
  probabilityMessage: string = '';
  userInfo: any;
  posts: any[] = [];
  title = 'TikTok Component';

  constructor(private tiktokService: TikTokService) {}

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    if (!username) {
      this.errorMessage = 'Username is required!';
      return;
    }
  
    this.tiktokService.getTikTokProfile(username).subscribe(
      response => {
        console.log('TikTok API response:', response);
        if (response && response.userInfo && response.userInfo.user) {
          this.user = response.userInfo.user;
          this.errorMessage = null;
  
          // Extracting followers and following count
          const stats = response.userInfo.stats;
          if (stats) {
            this.user.followerCount = stats.followerCount;
            this.user.followingCount = stats.followingCount;
          }
  
          this.calculateFakeProbability();
  
          // Check for secUid before fetching posts
          if (this.user.secUid) {
            this.getTikTokUserPosts(this.user.secUid);
          } else {
            console.error('secUid is missing in the API response.');
          }
        } else {
          this.errorMessage = 'TikTok user data not found in the API response.';
          this.user = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching TikTok profile: ${error.message}`;
        console.error('TikTok API error:', error);
      }
    );
  }
  

  calculateFakeProbability(): void {
    if (!this.user) {
      console.error('User is undefined in calculateFakeProbability');
      return;
    }
  
    let probability = 0;
    let usedCriteria = '';
  
    // Check if the username contains more than four numbers
    if (this.user.uniqueId && (this.user.uniqueId.match(/\d/g) || []).length > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }
  
    // Check if the account was created less than 1 year ago
    if (this.user.createTime && new Date().getTime() - this.user.createTime * 1000 < 365 * 24 * 60 * 60 * 1000) {
      probability += 50;
      usedCriteria += 'Account age is less than 1 year. ';
    }
  
    // Set the calculated probability and message
    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.uniqueId} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
    console.log(this.probabilityMessage);  // To check if this message is being logged
  }
  
  // Method to fetch TikTok posts
  getTikTokUserPosts(secUid: string): void {
    this.tiktokService.getUserPosts(secUid).subscribe(
      (data) => (this.posts = data.itemList),
      (error) => console.error(error)
    );
  }
}
