<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{ title }}</h1>

      <div>
        <input #username type="text" placeholder="TikTok Username">
        <button (click)="getUserInfo(username.value)">Get User Info</button>
      </div>
  
      <div *ngIf="errorMessage">
        <p style="color: red;">{{ errorMessage }}</p>
      </div>
       
      <div class="tiktok-profile" *ngIf="user">
        <img class="profile-image" [src]="user.avatarLarger" alt="User Avatar" />
        
        <div class="profile-details">
          <div class="profile-name">{{ user.nickname }}</div>
          <div class="profile-signature">{{ user.signature }}</div>
        </div>
      </div>
      
      <div class="stats" *ngIf="user">
        <div class="stat-item">
          <strong>{{ user.followerCount | number }}</strong><br> Followers
        </div>
        <div class="stat-item">
          <strong>{{ user.followingCount | number }}</strong><br> Following
        </div>
      </div>
      
      <div class="probability-message" *ngIf="probabilityMessage">
        {{ probabilityMessage }}
      </div>
      
      <div *ngIf="posts.length > 0" class="user-posts">
        <h3>User Posts</h3>
        <div *ngFor="let post of posts" class="post">
          <img [src]="post.video.originCover" alt="Post Cover" class="post-image" />
          <p>{{ post.desc }}</p>
          <p>{{ post.stats.playCount }} Plays | {{ post.stats.diggCount }} Likes</p>
        </div>
      </div>      
    </div>
  </div>
</div>
