// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  consumerKey: 'ccmFatpEuCsd4xbFfMNk3YoU7',
  consumerSecret: 'AI05zSWR44uwIGKSJ7UgvvrjduF2xAkxksOQmiDB9U5aV4IoL2',
  clientId: '780134460535976',
  apiUrl: 'https://37.120.189.106:4999ng /api',
  redirectUri: 'https://37.120.189.106:4999'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
