<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger">
      <app-sidebar-cmp></app-sidebar-cmp>
    </div>
  
    <div class="main-panel">
      <app-navbar-cmp></app-navbar-cmp>
  
      <div class="content">
        <h1>Account Information</h1>
        
        <!-- Registration Form -->
        <form (ngSubmit)="onRegister()">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="account.name" name="name" required>
          </div>
  
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" [(ngModel)]="account.email" name="email" required>
          </div>
  
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" [(ngModel)]="account.password" name="password" required>
          </div>
  
          <div class="form-group">
            <label for="mobileNo">Mobile Number</label>
            <input type="text" class="form-control" id="mobileNo" [(ngModel)]="account.mobileNo" name="mobileNo" required>
          </div>
  
          <div class="form-group">
            <label for="countryCode">Country Code</label>
            <input type="text" class="form-control" id="countryCode" [(ngModel)]="account.countryCode" name="countryCode" required>
          </div>
  
          <div class="form-group">
            <label for="dob">Date of Birth</label>
            <input type="date" class="form-control" id="dob" [(ngModel)]="account.dob" name="dob" required>
          </div>
  
          <div class="form-group">
            <label for="referalCode">Referral Code</label>
            <input type="text" class="form-control" id="referalCode" [(ngModel)]="account.referalCode" name="referalCode">
          </div>
  
          <div class="form-group">
            <label for="affiliateCode">Affiliate Code</label>
            <input type="text" class="form-control" id="affiliateCode" [(ngModel)]="account.affiliateCode" name="affiliateCode">
          </div>
  
          <button type="submit" class="btn btn-primary">Register</button>
        </form>
  
        <hr>
  
        <!-- Mobile Verification Section -->
        <h2>Verify Mobile Number</h2>
        <form (ngSubmit)="onVerifyMobile()">
          <div class="form-group">
            <label for="verifyMobileNo">Mobile Number</label>
            <input type="text" class="form-control" id="verifyMobileNo" [(ngModel)]="mobileVerification.mobileNo" name="verifyMobileNo" required>
          </div>
  
          <div class="form-group">
            <label for="verifyCode">Verification Code</label>
            <input type="text" class="form-control" id="verifyCode" [(ngModel)]="mobileVerification.code" name="verifyCode" required>
          </div>
  
          <button type="submit" class="btn btn-primary" [disabled]="isMobileVerified">Verify Mobile</button>
        </form>
  
        <!-- Error Handling -->
        <div *ngIf="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>
  
        <!-- Success Message -->
        <div *ngIf="isMobileVerified" class="alert alert-success">
          Mobile number verified successfully!
        </div>
  
      </div>
    </div>
  </div>
  