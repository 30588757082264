<div class="wrapper">

    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar-cmp></app-sidebar-cmp>
    
    </div>
  
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
  
        <div class="content">
             
            <h1>{{title}}</h1>
           
            <mat-grid-list cols="1" rowHeight="4:3">
                  

    <div class="description">{{ image.userName }}</div>
    <div class="author">{{ image.keyword }}</div>
    <div class="author">{{ image.platform }}</div>
    <img [src]="'data:image/png;base64,' + image.bytecode" alt="Image" class="image-frame"/>
    <div style="text-align: center; padding-top: 2%;">
        <!-- <form [formGroup]="paymentForm" > -->
        <button class="btn btn-success" type="button" (click)="payment()">Accept</button>
        <!-- </form> -->
        <button class="btn  btn-danger" (click)="deny(template,image.index)">Deny</button>
    </div>

                             </mat-grid-list>
           
         
        </div>
    </div>
  
  </div>
