<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group card-group-login-comp">
                        <div class="card p-4">
                            <div class="card-body">
                                <form [formGroup]="loginForm" (ngSubmit)="login()">
                                    <h1>Login</h1>
                                    <p class="text-muted">Sign In to your account</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-user"></i></span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="inputUserName" placeholder="Username" autocomplete="username" required>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" formControlName="inputPassword" placeholder="Password" autocomplete="current-password" required>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="true" data-use-continue-as="false"></div>

                                        </div>
                                     
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                                <button class="btn btn-primary active mt-3 instagram" (click)="loginWithInstagram()">Connect with my Instagram account</button>
                                        </div>
                                     
                                    </div>
                
                                    <div class="row">
                                        <div class="col-6">
                                            <button class="btn btn-primary px-4">Login</button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button type="button" class="btn btn-link px-0">Forgot password?</button>
                                        </div>
                                    </div>
                                </form>
                                <div *ngIf="msg" class="alert alert-danger" style="margin-top: 0.9rem;">
                                    <strong>Error!</strong> {{msg}}
                                </div>
                            </div>
                        </div>
                        <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                            <div class="card-body text-center">
                                <div>
                                    <h2>Sign up</h2>
                                    <p>We connect you with the Leading Brands of the World.</p>
                                    <div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="true" data-use-continue-as="false"></div>
                                   <button type="button" class="btn btn-primary active mt-3"> <a routerLink="/register">Register Now!</a></button>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
