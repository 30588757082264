import { Component, OnInit } from '@angular/core';
import {NgbConfig, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { NgbdCarouselBasic } from './carousel-basic';
import { AccountService } from './services';

@Component({
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'] 
})
export class AppComponent implements OnInit{
  constructor(ngbConfig: NgbConfig,
    protected accountService: AccountService,
    private router: Router) {
    ngbConfig.animation = false;
  }

  title : 'CPTDashboardRelaunch'


  ngOnInit() {
    
  }
}
