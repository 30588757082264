import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnapchatService } from '../services/snapchat.service';

@Component({
  selector: 'app-snapchat',
  templateUrl: './snap.component.html',
  styleUrls: ['./snap.component.scss']
})
export class SnapComponent implements OnInit {
  errorMessage: string | null = null;
  user: any;
  fakeProbability: number = 0;
  probabilityMessage: string = '';
  userInfo: any;
  relatedAccounts: any[] = [];
  title = 'Snapchat Component';

  constructor(private snapchatService: SnapchatService) {}

  ngOnInit(): void {}

  // Method to get Snapchat profile information and calculate the fake probability
  getUserInfo(username: string): void {
    if (!username) {
      this.errorMessage = 'Username is required!';
      return;
    }
    
    this.snapchatService.getSnapchatProfile(username).subscribe(
      response => {
        console.log('Snapchat API response:', response);
        if (response && response.data && response.data.info) {
          this.user = response.data.info;
          this.relatedAccounts = response.data.info.relatedAccountsInfo || [];
          this.errorMessage = null;

          this.calculateFakeProbability();
        } else {
          this.errorMessage = 'Snapchat user data not found in the API response.';
          this.user = null;
        }
      },
      (error: HttpErrorResponse) => {
        this.errorMessage = `An error occurred while fetching Snapchat profile: ${error.message}`;
        console.error('Snapchat API error:', error);
      }
    );
  }

  // Calculate the probability of the account being fake
  calculateFakeProbability(): void {
    if (!this.user) {
      console.error('User is undefined in calculateFakeProbability');
      return;
    }

    let probability = 0;
    let usedCriteria = '';

    // Check if the username contains more than four numbers
    if (this.user.username && (this.user.username.match(/\d/g) || []).length > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }

    // Check if the account was created less than 1 year ago (optional for Snapchat)
    if (this.user.creationTimestampMs && new Date().getTime() - parseInt(this.user.creationTimestampMs.value) < 365 * 24 * 60 * 60 * 1000) {
      probability += 50;
      usedCriteria += 'Account age is less than 1 year. ';
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.username} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
  }
}
