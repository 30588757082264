import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss']
})
export class AnalysisComponent implements OnInit {
  title = 'Analysis, evaluation and tracking';

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
  
  }
}
