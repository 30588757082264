/// <reference types="facebook-js-sdk" />

import { finalize } from 'rxjs';
import { AccountService } from '../services';
import { environment } from '../environments/environment';

declare const FB: any; // Declare FB as any type

export function appInitializer(accountService: AccountService) {
    return () => new Promise(resolve => {
        (window as any).fbAsyncInit = function () {
            FB.init({
                appId: environment.facebookAppId,
                cookie: true,
                xfbml: true,
                version: 'v8.0'
            });

            FB.getLoginStatus(({ authResponse }) => {
                if (authResponse) {
                    accountService.loginApi(authResponse.accessToken)
                        .pipe(finalize(() => resolve(null)))
                        .subscribe();
                } else {
                    resolve(null);
                }
            });
        };

        (function (d, s, id) {
            var js: any;
            var fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); 
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    });
}
