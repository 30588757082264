import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TwitterService } from '../services/twitter.service';

@Component({
  selector: 'app-x',
  templateUrl: './x.component.html',
  styleUrls: ['./x.component.scss']
})
export class XComponent implements OnInit {
  errorMessage: string | null = null;
  user: any;
  fakeProbability: number = 0;
  probabilityMessage: string = '';

  constructor(private twitterService: TwitterService) { }

  ngOnInit(): void {}

  getUserInfo(username: string): void {
    this.twitterService.getUser(username).subscribe(
      data => {
        this.user = data;
        this.errorMessage = null;
        this.calculateFakeProbability();
      },
      (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.errorMessage = 'Access forbidden. Please check your API credentials and permissions.';
        } else {
          this.errorMessage = 'An error occurred. Please try again later.';
        }
      }
    );
  }

  calculateFakeProbability(): void {
    let probability = 0;
    let usedCriteria = '';

    // Check for numbers in username
    const username = this.user.username;
    const numberCount = (username.match(/\d/g) || []).length;
    if (numberCount > 4) {
      probability += 50;
      usedCriteria += 'Username contains more than four numbers. ';
    }

    // Check account age, if available
    if (this.user.created_at) {
      const createdAt = new Date(this.user.created_at);
      const now = new Date();
      const ageInDays = Math.floor((now.getTime() - createdAt.getTime()) / (1000 * 3600 * 24));
      if (ageInDays < 365) {
        probability += 50;
        usedCriteria += 'Account age is less than 1 year. ';
      }
    } else {
      usedCriteria += 'Account age is not available. ';
    }

    this.fakeProbability = probability;
    this.probabilityMessage = `Probability of ${this.user.username} being a fake account: ${this.fakeProbability}%. Criteria used: ${usedCriteria}`;
  }
}
