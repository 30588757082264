import { Component, OnInit } from '@angular/core';
import { YoutubeService } from '../services/youtube.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {
  videos: any[] = [];
  channel: any;
  private unsubscribe$: Subject<any> = new Subject();
  title = 'Youtube component';

  constructor(private spinner: NgxSpinnerService, private youTubeService: YoutubeService) {}

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 3000);
    this.loadInitialVideos();
  }

  loadInitialVideos() {
    this.youTubeService
      .getVideosForChanel('UC_LtA_EtCr7Jp5ofOsYt18g', 15)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(lista => {
        this.videos = lista;
      });
  }

  getChannelInfo(identifier: string): void {
    this.youTubeService.getChannelInfo(identifier).subscribe(data => {
      this.channel = data.channel.items[0].snippet;
      this.videos = data.videos.items;
    });
  }
}
