import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { UrlDialogComponent } from '../url-dialog/url-dialog.component';

@Component({
  selector: 'app-fakenewsidentifier',
  templateUrl: './fakenewsidentifier.component.html',
  styleUrls: ['./fakenewsidentifier.component.scss']
})
export class FakenewsidentifierComponent {
  title = 'Fakenews Identifier';
  validationResult: string;

  constructor(public dialog: MatDialog, private http: HttpClient) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(UrlDialogComponent, {
      width: '400px',
      data: { valid: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.validateUrl(result.url, result.additionalText);
      }
    });
  }

  validateUrl(url: string, additionalText: string): void {
    this.http.post<{ valid: boolean, count: number }>('/api/validate-url', { url })
      .subscribe(response => {
        if (response.valid) {
          this.validationResult = `News source is valid and has been checked ${response.count} times.`;
        } else {
          this.openNonValidUrlDialog(url);
        }
      }, error => {
        this.validationResult = 'An error occurred while validating the URL.';
      });
  }

  openNonValidUrlDialog(url: string): void {
    const dialogRef = this.dialog.open(UrlDialogComponent, {
      width: '400px',
      data: { valid: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.post('/api/add-url', { url, additionalText: result.additionalText })
          .subscribe(() => {
            this.validationResult = 'URL and additional text have been stored.';
          }, error => {
            this.validationResult = 'An error occurred while storing the URL.';
          });
      }
    });
  }
}
