
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { first } from 'rxjs/operators';
import { FileService, PaymentService } from '../app/services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {NgFor, NgForOf} from "@angular/common";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import {MatGridListModule} from '@angular/material/grid-list';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
	selector: 'ngbd-carousel-basic',
	standalone: true,
	imports: [NgbCarouselModule, NgIf, NgFor,NgForOf, AsyncPipe],
	templateUrl: './carousel-basic.html',
})
export class NgbdCarouselBasic {
  images: any = []
  public data: any = [];
  currentIndex = 0;
  maxImages = 16;
  displayedImages: string[] = [];
  modalRef?: BsModalRef;
  template?: TemplateRef<any>;
  paymentForm: UntypedFormGroup;
  public imgArr: any = [];

  public list
  carouselImages: any;
  constructor(
    private fileService: FileService,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {
    this.data = []
  }
  ngOnInit() {
    this.fileService.fileList()
    .pipe(first())
    .subscribe(
      (list) => {
        this.data = list;
        this.data.forEach((e1, i) => {
          e1.index = i;
          this.images.push({
            bytecode: e1.bytecode,
            userName: e1.userName,
            platform: e1.platform,
            keyword: e1.keyword
          });
        });
        
        setInterval(() => {
          this.currentIndex = (this.currentIndex + this.maxImages) % this.images.length;
        }, 1000);
      },
      (error) => {
        if (error.error.error?.verifyUser) {
        } else {
   
        }
      }
    );
  }
  

  openDetailView(imageData: any) {
    this.router.navigate(['/detail'], { state: { image: imageData } });
  }
  


  payment() {
    this.paymentService
      .getPaymentLink()
      .pipe(first())
      .subscribe(
        (links: any) => {
          if (links) {
            let paymentLink = links.links.find(x => x.rel === 'approve');
            // this.router.navigate([paymentLink]);
            window.location.href = paymentLink.href
          }
        },
        (error) => {
          if (error.error.error?.verifyUser) {
            // this.openDialog(error.error.error?.user);
          } else {
            // this.snackBService.openSnackBar(
            //   `Incorrect Username or Password !!!`,
            //   false
            // );
          }
        }
      );
  }
}