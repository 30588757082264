<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>X Component</h1>

      <div>
        <input #username type="text" placeholder="Twitter Username">
        <button (click)="getUserInfo(username.value)">Get User Info</button>
      </div>

      <div *ngIf="errorMessage">
        <p style="color: red;">{{ errorMessage }}</p>
      </div>

      <div *ngIf="user">
        <h2>{{ user.name }} (&#64;{{ user.username }})</h2>
        <p><strong>ID:</strong> {{ user.id }}</p>
        <img *ngIf="user.profile_image_url" [src]="user.profile_image_url" alt="{{ user.name }}'s profile image">
        <p><strong>Followers:</strong> {{ user.followers_count }}</p>
        <p><strong>Following:</strong> {{ user.following_count }}</p>
        <p><strong>Bio:</strong> {{ user.description }}</p>
        <p><strong>Location:</strong> {{ user.location }}</p>
        <p><strong>Account Created At:</strong> {{ user.created_at | date:'short' }}</p>
        <p class="fake-probability" *ngIf="fakeProbability >= 0" style="color: red;">
          {{ probabilityMessage }}
        </p>
      </div>
    </div>
  </div>
</div>
