import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FileService, PaymentService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  [x: string]: any;
  image: any;
  userName: String;
  description: String;
  author: String;

  title = 'Detail View';
  modalRef: any;
  modalService: any;

  constructor(private route: ActivatedRoute,
    private fileService: FileService,
    private paymentService: PaymentService,
    private router: Router,) { }
  ngOnInit(): void { 
    this.image = history.state.image;
  }


  deny(template: TemplateRef<any>,index){
    console.log('called');
    this.modalRef.hide();
    if(this.image[index+1]){
      this.image = this.image[index+1]; // Dynamic Data
      this.modalRef = this.modalService.show(template);
    }
  }
  

  payment() {
    this.paymentService
      .getPaymentLink()
      .pipe(first())
      .subscribe(
        (links: any) => {
          if (links) {
            let paymentLink = links.links.find(x => x.rel === 'approve');
            // this.router.navigate([paymentLink]);
            window.location.href = paymentLink.href
          }
        },
        (error) => {
          if (error.error.error?.verifyUser) {
            // this.openDialog(error.error.error?.user);
          } else {
            // this.snackBService.openSnackBar(
            //   `Incorrect Username or Password !!!`,
            //   false
            // );
          }
        }
      );
  }
 

}
