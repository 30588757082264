<div class="animated fadeIn">
    <div class="card">
        <div class="card-header">
            <i class="fa fa-align-justify"></i> Image List
        </div>
        <ng-template #template>
            <div class="modal-header">
                <h3>{{list.platform}}</h3>
                <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
              <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div>
                    <p>{{list.keyword}}</p>
                </div>
                <div style="text-align: center;">
                    <img style="border:1px solid gray;max-width:450px; max-height: 350px;"
                    src="data:image/png;base64,{{list.bytecode}}" />
                </div>
                <div style="text-align: center; padding-top: 2%;">
                    <!-- <form [formGroup]="paymentForm" > -->
                    <button class="btn btn-success" type="button" (click)="payment()">Accept</button>
                    <!-- </form> -->
                    <button class="btn  btn-danger" (click)="deny(template,list.index)">Deny</button>
                </div>
            </div>
        </ng-template>

        <div class="card-body">
            <table class="table">
                <thead>
                    <tr>
                        <th>Platform</th>
                        <th>Keyword</th>
                        <th>Username</th>
                        <th>Datetime</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let list of data; let i=index">
                        <td>{{list.platform}}</td>
                        <td>{{list.keyword}}</td>
                        <td>{{list.username}}</td>
                        <td>{{list.createdAt}}</td>
                        <td><button class="btn  btn-primary" (click)="openModal(template, list)" data-id="{{list._id}}">Show</button></td>
                    </tr>
                </tbody>
            </table>
            <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#">Prev</a></li>
                <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item"><a class="page-link" onclick="callnextPage()" href="#">2</a></li>
                <li class="page-item"><a class="page-link" onclick="callnextPage()" href="#">3</a></li>
                <li class="page-item"><a class="page-link" onclick="callnextPage()" href="#">4</a></li>
                <li class="page-item"><a class="page-link" onclick="callnextPage()" href="#">Next</a></li>
            </ul>
        </div>
    </div>
</div>