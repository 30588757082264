<div class="app-body">
  <main class="main d-flex align-items-center">
      <div class="container">
          <div class="row">
              <div class="col-md-6 mx-auto">
                  <div class="card mx-4">
                      <div class="card-body p-4">
                          <form [formGroup]="registerForm" (ngSubmit)="register()">
                              <h1>Register</h1>
                              <p class="text-muted">Create your account</p>
                              <!-- <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-user"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Username" autocomplete="username" required>
              </div> -->
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text">&#64;</span>
                                  </div>
                                  <input type="text" class="form-control" formControlName="inputEmail" placeholder="Email" autocomplete="email" required>
                              </div>
                              <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                      <span class="input-group-text"><i class="icon-lock"></i></span>
                                  </div>
                                  <input type="password" class="form-control" formControlName="inputPassword" placeholder="Password" autocomplete="new-password" required>
                              </div>
                              <!-- <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-lock"></i></span>
                </div>
                <input type="password" class="form-control" placeholder="Repeat password" autocomplete="new-password" required>
              </div> -->
                              <button class="btn btn-block btn-success">Create Account</button>
                          </form>
                          <div *ngIf="showSuccess" class="alert alert-success" style="margin-top: 0.9rem;">
                              <strong>Success!</strong> E-mail sent, please verify the email
                          </div>
                      </div>
                      <!-- <div class="card-footer p-4">
            <div class="row">
              <div class="col-6">
                <button class="btn btn-block btn-facebook" type="button"><span>facebook</span></button>
              </div>
              <div class="col-6">
                <button class="btn btn-block btn-twitter" type="button"><span>twitter</span></button>
              </div>
            </div>
          </div> -->
                  </div>
              </div>
          </div>
      </div>
  </main>
</div>
