import { Component } from '@angular/core';

@Component({
  templateUrl: 'paymentFail.component.html'
})
export class PaymentFailComponent {

  constructor() { }

}
