import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  constructor(
    private router: Router,
  
  ) { }
  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
