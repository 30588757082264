<h2>Edit Account</h2>
<p>Updating your info here only changes it in the Angular example app, it does not (and cannot) change anything on Facebook itself.</p>
<form *ngIf="account" [formGroup]="form" (ngSubmit)="saveAccount()">
    <div class="mb-3">
        <label class="form-label">Facebook Id</label>
        <div>{{account.facebookId}}</div>
    </div>
    <div class="mb-3">
        <label class="form-label">Name</label>
        <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
            <div *ngIf="f.name.errors.required">Name is required</div>
        </div>
    </div>
    <div class="mb-3">
        <label class="form-label">Extra Info</label>
        <input type="text" formControlName="extraInfo" class="form-control" />
    </div>
    <div class="mb-3">
        <button type="submit" [disabled]="isSaving" class="btn btn-primary me-2">
            <span *ngIf="isSaving" class="spinner-border spinner-border-sm me-1"></span>
            Save
        </button>
        <a routerLink="/" class="btn btn-secondary me-2">Cancel</a>
        <button (click)="deleteAccount()" class="btn btn-danger" [disabled]="isDeleting">
            <span *ngIf="isDeleting" class="spinner-border spinner-border-sm me-1"></span>
            Delete
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
    </div>
</form>
<div *ngIf="!account" class="text-center p-3">
    <span class="spinner-border spinner-border-lg align-center"></span>
</div>
