<div class="wrapper">

    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar-cmp></app-sidebar-cmp>
    
    </div>
  
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
  
        <div class="content">       
            <h1>{{title}}</h1>
           </div>
    </div>
  </div>
  
