<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <div class="header-section">
        <h1>{{title}}</h1>
      </div>
      
      <div class="input-section">
        <button mat-raised-button color="primary" (click)="openDialog()">
          <h2>Click to enter News Source</h2>
        </button>
      </div>

      <div class="validation-result" *ngIf="validationResult">
        <p>{{ validationResult }}</p>
      </div>
    </div>
  </div>
</div>
