<div class="wrapper">
    <div class="sidebar" data-color="white" data-active-color="danger">
      <app-sidebar-cmp></app-sidebar-cmp>
    </div>
  
    <div class="main-panel">
      <app-navbar-cmp></app-navbar-cmp>
  
      <div class="content">
        <h1>{{title}}</h1>
        <div class="input-section">
          <input #username type="text" placeholder="Snapchat Username">
          <button mat-button (click)="getUserInfo(username.value)">Get User Info</button>
        </div>
  
        <div *ngIf="errorMessage">
          <p style="color: red;">{{ errorMessage }}</p>
        </div>
  
        <div *ngIf="user">
          <h2>{{ user.title }}</h2>
          <img [src]="user.profilePictureUrl" alt="User Avatar" width="100px" />
          <p>{{ user.bio }}</p>
          <p>Subscribers: {{ user.subscriberCount }}</p>
          <p>Website: <a [href]="user.websiteUrl" target="_blank">{{ user.websiteUrl }}</a></p>
          <p>{{ probabilityMessage }}</p>
  
          <div *ngIf="relatedAccounts.length > 0">
            <h3>Related Accounts</h3>
            <ul>
              <li *ngFor="let account of relatedAccounts">
                <img [src]="account.publicProfileInfo.profilePictureUrl" alt="Profile Pic" width="50px" />
                <p>{{ account.publicProfileInfo.title }} ({{ account.publicProfileInfo.username }})</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  