
import { Component, OnInit, TemplateRef } from '@angular/core';
import {NgbConfig, NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd,ActivatedRoute } from '@angular/router';
import { NgbdCarouselBasic } from '../carousel-basic';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { FileService, PaymentService } from '../services/';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public data: any = [];
  modalRef?: BsModalRef;
  paymentForm: UntypedFormGroup;

  public list
  constructor(
    private fileService: FileService,
    private paymentService: PaymentService,
    private modalService: BsModalService,
    private router: Router,
    config: NgbCarouselConfig,
    private http: HttpClient
  ) {
    this.data = [],
    config.interval = 4000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  title = 'News & media evaluation done right';
  profile: any;
  deny: any; 
  openModal: any; 



  ngOnInit() {
    this.fileService.fileList()
    .pipe(first())
    .subscribe(
      (list) => {
        this.data = list;
        this.data.forEach((e1,i) => {
          e1.index = i;
        });
      },
      (error) => {
        if (error.error.error?.verifyUser) {
        } else {
          // this.snackBService.openSnackBar(
          //   `Incorrect Username or Password !!!`,
          //   false
          // );
        }
      }
    );
  } 

 
  payment() {
    this.paymentService
      .getPaymentLink()
      .pipe(first())
      .subscribe(
        (links: any) => {
          if (links) {
            let paymentLink = links.links.find(x => x.rel === 'approve');
            // this.router.navigate([paymentLink]);
            window.location.href = paymentLink.href
          }
        },
        (error) => {
          if (error.error.error?.verifyUser) {

          } else {
      
          }
        }
      );
  }
}
