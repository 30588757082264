<div class="wrapper">

  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>

  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{title}}</h1>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <table>
          <tr>
            <td>Title:</td>
            <td><input type="text" formControlName="title" maxlength="100"></td>
          </tr>
          <tr>
            <td>Text:</td>
            <td><textarea formControlName="text" maxlength="500"></textarea></td>
          </tr>
          <tr>
            <td>Image:</td>
            <td><input type="file" formControlName="image"></td>
          </tr>
          <tr>
            <td>Video:</td>
            <td><input type="file" formControlName="video"></td>
          </tr>
          <tr>
            <td>Tags:</td>
            <td><input type="text" formControlName="tags"></td>
          </tr>
          <tr>
            <td>Language:</td>
            <td>
              <select formControlName="language">
                <option *ngFor="let language of languages" [value]="language">{{language}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Country:</td>
            <td>
              <select formControlName="country">
                <option *ngFor="let country of countries" [value]="country">{{country}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <td>Platform:</td>
            <td>
              <select formControlName="platform">
                <option *ngFor="let platform of platforms" [value]="platform">{{platform}}</option>
              </select>
              <input type="text" formControlName="additionalPlatforms">
            </td>
          </tr>
          <tr>
          <td>
            <div *ngIf="uploadedFile">
              <img [src]="thumbnailUrl" width="500" height="500">
              <p>Price: {{ price }}</p>
              <p>Tags: <span *ngFor="let tag of tagsArray">{{ tag }}</span></p>
            </div>
          </td>
        </tr>
        <tr>
        <td>
          <form (submit)="onSubmit()">
            <input type="file" (change)="onFileSelected($event)">
            <input type="number" placeholder="Price" [(ngModel)]="price">
            <input type="text" placeholder="Tags (comma-separated)" [(ngModel)]="tags">
            <button type="submit">Upload</button>
          </form>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
          </mat-form-field>
        </td>
      </tr>
        </table>
        <button type="submit">Submit</button>
      </form>
    </div>
  </div>
