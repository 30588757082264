<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{ title }}</h1>

      <!-- Form to retrieve user info -->
      <div class="input-section">
        <input #username type="text" placeholder="Telegram Username" class="input-field">
        <button (click)="getUserInfo(username.value)" class="button">Get User Info</button>
      </div>

      <!-- Form to retrieve group info -->
      <div class="input-section">
        <input #groupUsername type="text" placeholder="Telegram Group Username" class="input-field">
        <button (click)="getGroupInfo(groupUsername.value)" class="button">Get Group Info</button>
      </div>

      <!-- Form to retrieve channel info -->
      <div class="input-section">
        <input #channelUsername type="text" placeholder="Telegram Channel Username" class="input-field">
        <button (click)="getChannelInfo(channelUsername.value)" class="button">Get Channel Info</button>
      </div>

      <!-- Form to retrieve bot info -->
      <div class="input-section">
        <input #botUsername type="text" placeholder="Telegram Bot Username" class="input-field">
        <button (click)="getBotInfo(botUsername.value)" class="button">Get Bot Info</button>
      </div>

      <!-- Error message -->
      <div *ngIf="errorMessage">
        <p class="error-message">{{ errorMessage }}</p>
      </div>

      <!-- User info display -->
      <div *ngIf="userInfo">
        <h2>User Info</h2>
        <p><strong>First Name:</strong> {{ userInfo.first_name }}</p>
        <p><strong>Last Name:</strong> {{ userInfo.last_name || 'N/A' }}</p>
        <p><strong>Username:</strong> {{ userInfo.username }}</p>
        <p><strong>ID:</strong> {{ userInfo.id }}</p>
        <p><strong>About:</strong> {{ userInfo.about || 'N/A' }}</p>
      </div>

      <!-- Group info display -->
      <div *ngIf="groupInfo">
        <h2>Group Info</h2>
        <p><strong>Title:</strong> {{ groupInfo.title }}</p>
        <p><strong>Participants Count:</strong> {{ groupInfo.participants_count }}</p>
        <p><strong>Online Count:</strong> {{ groupInfo.online_count }}</p>
      </div>

      <!-- Channel info display -->
      <div *ngIf="channelInfo">
        <h2>Channel Info</h2>
        <p><strong>Title:</strong> {{ channelInfo.title }}</p>
        <p><strong>Participants Count:</strong> {{ channelInfo.participants_count }}</p>
        <p><strong>Created At:</strong> {{ channelInfo.created_at }}</p>
        <p><strong>About:</strong> {{ channelInfo.about }}</p>
      </div>

      <!-- Bot info display -->
      <div *ngIf="botInfo">
        <h2>Bot Info</h2>
        <p>{{ botInfo.data }}</p>
      </div>
    </div>
  </div>
</div>
