<h1 mat-dialog-title>Enter News Source</h1>
<div mat-dialog-content>
  <form [formGroup]="urlForm">
    <mat-form-field>
      <mat-label>URL</mat-label>
      <input matInput formControlName="url" placeholder="https://example.com">
      <mat-error *ngIf="urlForm.get('url').hasError('required')">URL is required</mat-error>
      <mat-error *ngIf="urlForm.get('url').hasError('pattern')">Invalid URL format</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!data.valid">
      <mat-label>Additional Text</mat-label>
      <input matInput formControlName="additionalText" placeholder="Add some text...">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [disabled]="urlForm.invalid" (click)="onSubmit()">Submit</button>
</div>
