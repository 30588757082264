
  
  <div class="wrapper">

    <div class="sidebar" data-color="white" data-active-color="danger">
        <app-sidebar-cmp></app-sidebar-cmp>
    
    </div>
  
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
  
        <div class="content">
             
            <h1>{{title}}</h1>
           


<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> {{row.id}} </td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Retention rate </th>
      <td mat-cell *matCellDef="let row"> {{row.progress}}% </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <!-- Fruit Column -->
    <ng-container matColumnDef="fruit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Fields of interest </th>
      <td mat-cell *matCellDef="let row"> {{row.fruit}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
    <tr class="mat-row" *matNoDataRow><mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator></tr>
  </table>

  
</div>


    
        </div>
         
        </div>
    </div>
  

