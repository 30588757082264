<div class="wrapper">
  <div class="sidebar" data-color="white" data-active-color="danger">
    <app-sidebar-cmp></app-sidebar-cmp>
  </div>

  <div class="main-panel">
    <app-navbar-cmp></app-navbar-cmp>

    <div class="content">
      <h1>{{title}}</h1>

      <mat-grid-list cols="1" rowHeight="4:3">
        <div *ngFor="let video of videos" class="col-xl-3 col-md-6 mb-4">
          <div class="card border-0 shadow vh-50">
            <a *ngIf="video.id?.videoId" href="https://www.youtube.com/watch?v={{video.id.videoId}}" target="_blank">
              <img [src]="video.snippet.thumbnails.medium.url" class="card-img-top" alt="...">
            </a>
            <div class="card-body text-center">
              <h5 class="card-title mb-0">
                <a *ngIf="video.id?.videoId" href="https://www.youtube.com/watch?v={{video.id.videoId}}">
                  {{video.snippet.title}}
                </a>
              </h5>
              <div class="card-text text-black-50">{{video.snippet.description.slice(0, 100)}}</div>
              <p class="card-text">{{video.snippet.description}}</p>
            </div>
          </div>
        </div>
      </mat-grid-list>

      <div>
        <input #channelId type="text" placeholder="YouTube Channel ID">
        <button (click)="getChannelInfo(channelId.value)">Get Channel Info</button>
      </div>

      <div *ngIf="channel">
        <h3>{{ channel.title }}</h3>
        <p>{{ channel.description }}</p>
      </div>

      <div *ngIf="videos && videos.length">
        <h4>Recent Videos</h4>
        <ul>
          <li *ngFor="let video of videos">
            <a *ngIf="video.snippet.resourceId?.videoId" [href]="'https://www.youtube.com/watch?v=' + video.snippet.resourceId.videoId" target="_blank">
              {{ video.snippet.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
