import { Component } from '@angular/core';
import { TelegramService } from '../services/telegram.service';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  styleUrls: ['./telegram.component.scss']
})
export class TelegramComponent {
  title: string = 'Identify Telegram Account, Group, Channel, or Bot';
  userInfo: any = null;  // For storing user info
  groupInfo: any = null;  // For storing group info
  channelInfo: any = null;  // For storing channel info
  botInfo: any = null;  // For storing bot info
  errorMessage: string | null = null;

  constructor(private telegramService: TelegramService) {}

  // Fetch Telegram user info
  getUserInfo(username: string): void {
    this.telegramService.getTelegramUserInfo(username).subscribe(
      data => {
        this.userInfo = data;
        this.clearOtherInfo('user');
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram group info
  getGroupInfo(groupUsername: string): void {
    this.telegramService.getTelegramGroupInfo(groupUsername).subscribe(
      data => {
        this.groupInfo = data;
        this.clearOtherInfo('group');
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram channel info
  getChannelInfo(channelUsername: string): void {
    this.telegramService.getTelegramChannelInfo(channelUsername).subscribe(
      data => {
        this.channelInfo = data;
        this.clearOtherInfo('channel');
      },
      error => this.handleError(error)
    );
  }

  // Fetch Telegram bot info
  getBotInfo(botUsername: string): void {
    this.telegramService.getTelegramBotInfo(botUsername).subscribe(
      data => {
        this.botInfo = data;
        this.clearOtherInfo('bot');
      },
      error => this.handleError(error)
    );
  }

  // Clear other info except the current one being searched
  clearOtherInfo(infoType: string): void {
    if (infoType !== 'user') this.userInfo = null;
    if (infoType !== 'group') this.groupInfo = null;
    if (infoType !== 'channel') this.channelInfo = null;
    if (infoType !== 'bot') this.botInfo = null;
    this.errorMessage = null;
  }

  // Handle errors
  handleError(error: any): void {
    this.errorMessage = 'An error occurred. Please try again later.';
    console.error(error);
  }
}
