<div class="app flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">500</h1>
            <h4 class="pt-3">Payment Failed</h4>
            <p class="text-muted">Your payment was not completed successfully</p>
          </div>
          <div class="input-prepend input-group">
            <span class="input-group-append">
                <a href="#/files"><button class="btn btn-info" type="button">Go Back To Dashboard</button></a>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  