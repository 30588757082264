import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnapchatService {

  private apiKey = 'f95508dfb3mshf104f5772f5101dp1a8c82jsn6f8dab59315e'; // Replace with your actual key
  private apiHost = 'snapchat3.p.rapidapi.com';

  constructor(private http: HttpClient) {}

  // Method to get Snapchat profile information by username
  getSnapchatProfile(username: string): Observable<any> {
    const url = `https://${this.apiHost}/getProfile?username=${username}`;
    const headers = new HttpHeaders({
      'x-rapidapi-key': this.apiKey,
      'x-rapidapi-host': this.apiHost,
      'Accept': 'application/json'
    });
    
    return this.http.get(url, { headers });
  }
}
